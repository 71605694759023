



































































































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";

import api from "@/api";
import {ExamQuestionChoiceItemDto, ExamQuestionDto, QuestionType, SaveDto} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SingleFileUploadOSS from "@/components/SingleFileUploadOSS/index.vue";

@Component({
  name: "EditExam",
  components: {
    "single-file-upload-oss": SingleFileUploadOSS,
  }
})
export default class EditAnchor extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({required: true})
  dataId!: number;

  defaultData: ExamQuestionDto = {
    choiceItems: [],
    id: 0
  };

  questionTypeList = [
    {name: "单选", value: QuestionType.Radio},
    {name: "多选", value: QuestionType.CheckBox},
    {name: "判断", value: QuestionType.YesOrNo},
    {name: "问答", value: QuestionType.QuestionAndAnswer}
  ];

  show = false;
  form: ExamQuestionDto = {...this.defaultData};
  character = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "X",
    "Y",
    "Z"
  ];
  submitForm: SaveDto = {
    question: {},
    choiceItems: [],
    id: 0
  };
  linkRule = {
    questionType: [
      {
        required: true,
        message: "请选择题型",
        trigger: "blur"
      }
    ],
    question: [
      {
        required: true,
        message: "请输入试题题目",
        trigger: "change"
      }
    ]
  };

  get title() {
    if (this.dataId) {
      return `编辑`;
    } else {
      return "新建";
    }
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog"
      });

      if (this.dataId) {
        await api.examQuestion
          .get({id: this.dataId})
          .then((res: ExamQuestionDto) => {
            this.form = res!;
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = {...this.defaultData};
        //
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = {...this.defaultData};
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    //非问答题需要正确答案
    if (this.form.questionType != QuestionType.QuestionAndAnswer) {
      if (this.form.choiceItems!.length >= 2 == false) {
        this.$message.warning("至少需要两个答案选项");
        return false;
      }
      let emptyOptions = this.form.choiceItems!.filter((s) => !s.option);
      if (emptyOptions && emptyOptions.length > 0) {
        this.$message.warning("答案选项内容不能为空");
        return false;
      }
      let checkedOptions = this.form.choiceItems!.filter((s) => s.isChecked);
      if (this.form.questionType == QuestionType.CheckBox) {
        if (checkedOptions.length > 1 == false) {
          this.$message.warning("当前题型至少需2个正确答案");
          return false;
        }
      } else {
        if (checkedOptions.length != 1) {
          this.$message.warning("当前题型只需要一个正确答案");
          return false;
        }
      }
    }

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.submitForm.id = this.dataId;
        this.submitForm.question!.question = this.form.question!;
        this.submitForm.question!.creationTime = this.form.creationTime;
        this.submitForm.question!.point = 10;
        this.submitForm.question!.questionType = this.form.questionType!;
        this.submitForm.question!.id = this.dataId!;
        //
        let index = 0;
        this.submitForm.choiceItems = this.form.choiceItems!.map((s) => {
          return {
            questionId: 0,
            option: s.option,
            isChecked: s.isChecked,
            optionSort: this.character[index++],
            url: s.url
          };
        });
        let rightOptions = this.submitForm.choiceItems!.filter(
          (s) => s.isChecked
        );
        this.submitForm.question!.answer = rightOptions!
          .map((s) => s.optionSort)
          .join(",");
        if (this.dataId) {
          await api.examQuestion.updateWithChoiceItem({
            body: {...this.submitForm}
          });
        } else {
          await api.examQuestion.createWithChoiceItem({
            body: {...this.submitForm}
          });
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave");
      }
    });
  }

  setChoiceItem() {
    if (this.form.questionType == QuestionType.YesOrNo) {
      let obj = [
        {
          questionId: 0,
          option: "是",
          optionSort: "A",
          isChecked: false
        },
        {
          questionId: 0,
          option: "否",
          optionSort: "B",
          isChecked: true
        }
      ];
      this.form.choiceItems = obj;
    } else {
      this.form.choiceItems = [];
    }
  }

  cancel() {
    this.show = false;
  }

  // 添加一行
  private addRow() {
    let obj = {
      /**  */
      questionId: 0,

      /** 选项值 */
      option: "",

      /** 选项编号 */
      optionSort: ""
    };
    this.form.choiceItems = [...this.form.choiceItems!, obj];
  }

  // 删除整行
  private deleteRow(index: number) {
    this.form.choiceItems!.splice(index, 1);
  }

  // 删除整行
  private deleteAttachment(item: ExamQuestionChoiceItemDto) {
    item.url = ""
  }
}
